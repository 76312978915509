import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "./new.scss";
import { Backdrop, Box, Button, CircularProgress, Modal } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { useBarcode } from "next-barcode";
import { GetCallApi } from "../../../../../../Action/Action";
import { useSelector } from "react-redux";
import { MdDelete, MdRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import Webcam from "react-webcam";
import CropModal from "../../../../../../Components/Reusable/CropModel";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: "user",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function CreateStudentForm() {
  // const [openCircle, setOpenCircle] = useState(false);
  const [studId, setStudId] = useState();
  const [derror] = useState();
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(null);
  const [openTake, setOpenTake] = useState(false);
  const [picture, setPicture] = useState("");
  const [pictureShow, setPictureShow] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [allerGroupList, setAllerGroupList] = useState([]);
  const [schoolDistrictList, setschoolDistrictList] = useState([]);

  const webcamRef = useRef(null);
  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
  }, [webcamRef]);

  let navigate = useNavigate();

  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  const {
    fields: PrimaryHouseInfo,
    append: appendHouseHold,
    remove: removeHouseHold,
  } = useFieldArray({
    control,
    name: "primaryHouseHold",
  });

  const {
    fields: SecondaryHouseInfo,
    append: appendSecondHouseHold,
    remove: removeSecondHouseHold,
  } = useFieldArray({
    control,
    name: "secondaryHouseHold",
  });

  useEffect(() => {
    appendHouseHold({
      guardianFirstName: "",
      guardianLastName: "",
      phone: "",
      address: "",
      city: "",
      email: "",
    });
    appendSecondHouseHold({
      secHouseHoldName: "",
      secGuardian: "",
    });
    GetSchoolDistrictData();
    setStudId(generateId(15));
    GetSingleAllergenList();
    // eslint-disable-next-line
  }, []);

  const handleOpenTake = () => setOpenTake(true);
  const handleCloseTake = () => {
    setOpenTake(false);
    setValue("ProductImageUrl1", "");
    setValue("logoUpload1", "");
    setPicture("");
  };

  const handleProfileAdd = () => {
    let addpc = dataURLtoFile(picture, "profilePicture.jpeg");
    setPictureShow(URL.createObjectURL(addpc));
    setValue("ProductImageUrl1", addpc);
    setValue("logoUpload1", "profilePicture.jpeg");
    setOpenTake(false);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const GetSingleAllergenList = async () => {
    setAllerGroupList([]);
    let apiUrl = {
      url: `/api/Common/GetAllergenGroupList`,
    };

    const respo = await GetCallApi(apiUrl);
    if (respo?.status === 200) {
      if (respo?.data?.success) {
        let dlList = respo?.data?.payload?.map((x) => {
          return { ...x };
        });
        setAllerGroupList(dlList ?? []);
      }
    }
  };

  const GetSchoolDistrictData = async () => {
    setschoolDistrictList([]);
    let districtData = {
      url: `/api/SchoolDistrict/GetAllSchoolDistrictList?userId=${LogDetail?.userId}`,
    };
    let resp = await GetCallApi(districtData);
    if (resp?.status === 200 && resp?.data?.succes) {
      setschoolDistrictList(resp?.data.payload ?? []);
    } else {
      setschoolDistrictList([]);
    }
  };

  const getSchoolList = async (id) => {
    if (id) {
      let schoolData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
      };
      let res = await GetCallApi(schoolData);
      if (res?.status === 200 && res?.data?.succes) {
        setSchoolList(res.data.payload ?? []);
      } else {
        setSchoolList([]);
      }
    }
  };

  const { inputRef } = useBarcode({
    value: studId,
    options: {
      background: "#ffffff",
    },
  });

  function generateId(len) {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
  }

  function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
  }

  const handleImageChange = (event) => {
    setPictureShow("");
    setPicture("");
    setValue("ProductImageUrl1", "");
    setValue("logoUpload1", "");
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      setFileName1(event.target.files[0].name);
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const currentDate = new Date();

    const filesWithDate = selectedFiles.map((file) => ({
      id: v4(),
      file: file,
      name: file.name,
      type: file.type,
      uploadDate: currentDate.toLocaleDateString(),
    }));

    if (filesWithDate.length) {
      setFiles((prevFiles) => [...prevFiles, ...filesWithDate]);
      setFileNames((prevNames) => [
        ...prevNames,
        ...filesWithDate?.map((file) => {
          return { id: file?.id, name: file.name };
        }),
      ]);
    }
  };

  const triggerFileInput = () => {
    document.getElementById("file_input").click();
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFiles(droppedFiles);
  };

  const handleFiles = (newFiles) => {
    if (newFiles.length) {
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setFileNames((prevNames) => [
        ...prevNames,
        ...newFiles.map((file) => {
          return { id: file?.id, name: file.name };
        }),
      ]);
    }
  };

  const handleRemoveDocs = (val) => {
    setFiles(files?.filter((d) => d?.id !== val?.id));
    setFileNames(fileNames?.filter((d) => d?.id !== val?.id));
  };

  const openFileInNewTab = (id) => {
    const fileData = files?.find((c) => c?.id === id);

    if (fileData) {
      const fileBlob = new Blob([fileData.file], { type: fileData.type });
      const fileURL = URL.createObjectURL(fileBlob);

      window.open(fileURL, "_blank");
    } else {
      console.error("File data is not available.");
    }
  };

  const [src1, setSrc1] = useState(null);
  const [filename1, setFileName1] = useState(null);
  const [croppedImageUrl1, setCroppedImageUrl1] = useState(null);
  const [openCropModal1, setCropModal1] = useState(false);
  const setCroppedImageUrlCallback1 = useCallback(
    (url) => {
      if (url) {
        setValue("ProductImageUrl2", url);
        setValue("coverUpload1", url.name);
        setCroppedImageUrl1(url);
        setSrc1(null);
      } else {
        setSrc1(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl1]
  );

  const onSubmit = async (data) => { };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      // open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="create_student_new">
          <div className="main_wrapper">
            <div className="ProductCategories_hed mb-3">
              <div className="all_title ProductTitle">
                Create Student
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <form action="" onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="profile-picture-container">
                        <label htmlFor="image-upload" className="profile-picture">
                          {pictureShow ? (
                            <img
                              src={pictureShow ? pictureShow : ""}
                              alt="Profile"
                            />
                          ) : image ? (
                            <img
                              src={image ? image : ""}
                              alt="Profile"
                            />
                          ) : (
                            <span>150 x 150</span>
                          )}
                        </label>
                        <input
                          type="file"
                          id="image-upload"
                          accept="image/*"
                          onChange={handleImageChange}
                          style={{ display: "none" }}
                        />
                        {src1 && (
                          <CropModal
                            src={src1}
                            filename={filename1}
                            setCroppedImageUrl={setCroppedImageUrlCallback1}
                            openCropModal={openCropModal1}
                            setCropModal={setCropModal1}
                          />
                        )}
                        <p className="caption">Student Profile Picture</p>
                        <div className="upload_img">
                          <Button className="theme_btn" onClick={handleOpenTake}>
                            Take
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="barcode_img">
                          <svg ref={inputRef} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <h5 className="student_title mt-4">Student Information</h5>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          First Name <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter First Name"
                          {...register("firstName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.firstName && (
                          <span role="alert" className="error_text">
                            {errors.firstName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Last Name <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Last Name"
                          {...register("lastName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.lastName && (
                          <span role="alert" className="error_text">
                            {errors.lastName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Date of Birth <span className="red-color">*</span>
                        </label>
                        {/* <ReactDatePicker
                      showIcon
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    /> */}
                        <input
                          type="date"
                          className="form-control"
                          {...register("birthDate", {
                            required: "This field is required",
                          })}
                        />
                        {errors.birthDate && (
                          <span role="alert" className="error_text">
                            {errors.birthDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Joining Date <span className="red-color">*</span>
                        </label>
                        {/* <ReactDatePicker
                      showIcon
                      selected={joinDate}
                      onChange={(date) => setJoinDate(date)}
                    /> */}
                        <input
                          type="date"
                          className="form-control"
                          {...register("joiningDate", {
                            required: "This field is required",
                          })}
                        />
                        {errors.joiningDate && (
                          <span role="alert" className="error_text">
                            {errors.joiningDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Grade <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Grade"
                          {...register("gradeId", {
                            required: "This field is required",
                          })}
                        />
                        {errors.gradeId && (
                          <span role="alert" className="error_text">
                            {errors.gradeId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          School District <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {schoolDistrictList?.map((item, i) => (
                            <option value={item.schoolDistrictId} key={i}>
                              {item.districtName}
                            </option>
                          ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Gender <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("gender", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </select>
                        {errors.gender && (
                          <span role="alert" className="error_text">
                            {errors.gender.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Student Id <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Id"
                          {...register("studentNumber", {
                            required: "This field is required",
                          })}
                        />
                        {errors.studentNumber && (
                          <span role="alert" className="error_text">
                            {errors.studentNumber.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Password <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter password"
                          {...register("password", {
                            required: "This field is required",
                            pattern: {
                              message: "Enter password",
                            },
                          })}
                        />
                        {errors.password && (
                          <span role="alert" className="error_text">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <h5 className="student_title mt-4">School Information</h5>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          School <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select School </option>
                          {schoolList &&
                            schoolList?.map((item, i) => (
                              <option value={item.schoolId} key={i}>
                                {item.schoolName}
                              </option>
                            ))}
                        </select>
                        {errors.schoolId && (
                          <span role="alert" className="error_text">
                            {errors.schoolId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Homeroom</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Homeroom"
                          {...register("homeroom", {
                            required: "This field is required",
                          })}
                        />
                        {errors.homeroom && (
                          <span role="alert" className="error_text">
                            {errors.homeroom.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <h5 className="student_title mt-4">
                      Primary Household Information
                    </h5>
                    {PrimaryHouseInfo &&
                      PrimaryHouseInfo?.map((_, ind) => {
                        return (
                          <div className="card mb-4" key={ind}>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>
                                    Guardian First Name{" "}
                                    <span className="red-color">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Guardian First Name"
                                    {...register(
                                      `primaryHouseHold.${ind}.guardianFirstName`,
                                      {
                                        required: "This field is required",
                                      }
                                    )}
                                  />
                                  {errors.primaryHouseHold?.[ind]
                                    ?.guardianFirstName && (
                                      <span role="alert" className="error_text">
                                        {
                                          errors?.primaryHouseHold?.[ind]
                                            ?.guardianFirstName?.message
                                        }
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>
                                    Guardian Last Name{" "}
                                    <span className="red-color">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Guardian Last Name"
                                    {...register(
                                      `primaryHouseHold.${ind}.guardianLastName`,
                                      {
                                        required: "This field is required",
                                      }
                                    )}
                                  />
                                  {errors.primaryHouseHold?.[ind]
                                    ?.guardianLastName && (
                                      <span role="alert" className="error_text">
                                        {
                                          errors.primaryHouseHold?.[ind]
                                            ?.guardianLastName?.message
                                        }
                                      </span>
                                    )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>
                                    Phone Number{" "}
                                    <span className="red-color">*</span>{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Phone Number"
                                    {...register(
                                      `primaryHouseHold.${ind}.phone`,
                                      {
                                        required: "This field is required",
                                        pattern: {
                                          value:
                                            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                          message: "Enter valid phone number",
                                        },
                                      }
                                    )}
                                  />
                                  {errors.primaryHouseHold?.[ind]?.phone && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.primaryHouseHold?.[ind]?.phone
                                          .message
                                      }
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>
                                    Address <span className="red-color">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter address"
                                    {...register(
                                      `primaryHouseHold.${ind}.address`,
                                      {
                                        required: "This field is required",
                                      }
                                    )}
                                  />
                                  {errors.primaryHouseHold?.[ind]?.address && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.primaryHouseHold?.[ind]?.address
                                          .message
                                      }
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>
                                    City <span className="red-color">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter city"
                                    {...register(`primaryHouseHold.${ind}.city`, {
                                      required: "This field is required",
                                    })}
                                  />
                                  {errors.primaryHouseHold?.[ind]?.city && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.primaryHouseHold?.[ind]?.city
                                          .message
                                      }
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>
                                    Email <span className="red-color">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Email "
                                    {...register(
                                      `primaryHouseHold.${ind}.email`,
                                      {
                                        required: "This field is required",
                                        pattern: {
                                          value: /\S+@\S+\.\S+/,
                                          message:
                                            "Entered value does not match email format",
                                        },
                                      }
                                    )}
                                  />
                                  {errors.primaryHouseHold?.[ind]?.email && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.primaryHouseHold?.[ind]?.email
                                          .message
                                      }
                                    </span>
                                  )}
                                </div>
                              </div>
                              {ind === 0 ? (
                                ""
                              ) : (
                                <>
                                  <div className="col-md-12 text-end mb-2 pe-3">
                                    <Button
                                      type="button"
                                      onClick={() => removeHouseHold(ind)}
                                      className="delete_btn"
                                    >
                                      <AiOutlineClose className="icon" />
                                    </Button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    <div className="row col-md-12">
                      <div className="text-end mb-2">
                        <Button
                          className="theme_btn"
                          onClick={() =>
                            appendHouseHold({
                              guardianFirstName: "",
                              guardianLastName: "",
                              phone: "",
                              address: "",
                              city: "",
                              email: "",
                            })
                          }
                        >
                          <AiOutlinePlus className="icon" />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <h5 className="student_title mt-4">Household Information</h5>
                    {SecondaryHouseInfo &&
                      SecondaryHouseInfo?.map((_, index) => {
                        return (
                          <div
                            className={`row ${SecondaryHouseInfo?.length - 1 === index
                                ? "mt-4"
                                : "border-bottom mt-4"
                              }`}
                            key={index}
                          >
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Secondary Household Name"
                                  {...register(
                                    `secondaryHouseHold.${index}.secHouseHoldName`,
                                    {
                                      // required: "This field is required",
                                    }
                                  )}
                                />
                                {errors.secondaryHouseHold?.[index]
                                  ?.secHouseHoldName && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors?.secondaryHouseHold?.[index]
                                          ?.secHouseHoldName?.message
                                      }
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Guardian"
                                  {...register(
                                    `secondaryHouseHold.${index}.secGuardian`,
                                    {
                                      required: "This field is required",
                                    }
                                  )}
                                />
                                {errors.secondaryHouseHold?.[index]
                                  ?.secGuardian && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.secondaryHouseHold?.[index]
                                          ?.secGuardian?.message
                                      }
                                    </span>
                                  )}
                              </div>
                            </div>
                            {index === 0 ? (
                              <div className="col-md-1 text-end mb-2">
                                <label></label>
                                <Button
                                  className="theme_btn"
                                  onClick={() =>
                                    appendSecondHouseHold({
                                      secHouseHoldName: "",
                                      secGuardian: "",
                                    })
                                  }
                                >
                                  <AiOutlinePlus className="icon" />
                                </Button>
                              </div>
                            ) : (
                              <div className="col-md-1 text-end mb-2">
                                <label></label>
                                <Button
                                  type="button"
                                  onClick={() => removeSecondHouseHold(index)}
                                  className="delete_btn"
                                >
                                  <AiOutlineClose className="icon" />
                                </Button>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                  <div className="row">
                    <h5 className="student_title mt-4">Special Diets</h5>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label">Allergen Group</label>
                        <select
                          name=""
                          className="form-select"
                          {...register("AllergenGroupId", {
                            // required: "This field is required",
                          })}
                        >
                          <option value="">Select Allergen Group</option>
                          {allerGroupList &&
                            allerGroupList?.map((x, ind) => {
                              return (
                                <option value={x?.allergenGroupId} key={ind}>
                                  {x?.allergenGroupName}
                                </option>
                              );
                            })}
                        </select>
                        {errors.AllergenGroupId && (
                          <span role="alert" className="error_text">
                            {errors.AllergenGroupId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Dietary Restrictions</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Dietary Restrictions"
                          {...register("dietaryRestrictions", {})}
                        />
                        {errors.dietaryRestrictions && (
                          <span role="alert" className="error_text">
                            {errors.dietaryRestrictions.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Other Health Considerations</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Other Health Considerations"
                          {...register("dietaryRestrictions", {})}
                        />
                        {errors.dietaryRestrictions && (
                          <span role="alert" className="error_text">
                            {errors.dietaryRestrictions.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <h5 className="student_title mt-4">Document Management</h5>
                    <div className="col-md-12">
                      <div
                        id="cmp_docs_display"
                        className="drag_drop"
                        onClick={triggerFileInput}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                      >
                        {fileNames.length === 0 ? (
                          "Drag and drop files here or click to upload."
                        ) : (
                          <div>{fileNames?.map((c) => c?.name)?.join(", ")}</div>
                        )}
                      </div>
                      <input
                        type="file"
                        id="file_input"
                        accept=".pdf"
                        className="form-control"
                        style={{ display: "none" }}
                        onChange={onFileChange}
                      />
                      <input
                        type="hidden"
                        {...register("invoiceURL", {
                          required: "This field is required",
                        })}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="item_table table-responsive mt-5">
                        <table className="table table-bordered">
                          <thead>
                            <tr
                              style={{
                                backgroundColor: "#d4eed6",
                              }}
                            >
                              <th>Document Type</th>
                              <th>Uploaded Date</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {files &&
                              files?.map((x, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{x?.name}</td>
                                    <td>{x?.uploadDate}</td>
                                    <td>
                                      <Button
                                        type="button"
                                        className="btn_edit me-2"
                                        onClick={() => openFileInNewTab(x?.id)}
                                        style={{
                                          color: "#80c242",
                                          background: "transparent",
                                          minWidth: "max-content",
                                        }}
                                      >
                                        <MdRemoveRedEye fontSize={24} />
                                      </Button>
                                      <Button
                                        type="button"
                                        className="btn_dlt"
                                        onClick={() => handleRemoveDocs(x)}
                                        style={{
                                          color: "#80c242",
                                          background: "transparent",
                                          minWidth: "max-content",
                                        }}
                                      >
                                        <MdDelete
                                          className="icon"
                                          fontSize={24}
                                        />
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            {files?.length === 0 && (
                              <>
                                <tr className="all_list">
                                  <td
                                    className="text-center f-22-400 p-5"
                                    colSpan={3}
                                  >
                                    No Data Found
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 text-end">
                    <Button type="submit" className="theme_btn me-2">
                      Submit
                    </Button>
                    <Button className="cancel_btn" onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
      {openTake && (
        <>
          <Modal
            open={openTake}
            onClose={handleCloseTake}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                <h6>Capture Profile Picture</h6>
                <div className="popup_delete_detail">
                  <div className="mb-3">
                    {picture === "" ? (
                      <Webcam
                        audio={false}
                        height={400}
                        ref={webcamRef}
                        width={400}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                      />
                    ) : (
                      <img src={picture} alt="" />
                    )}
                  </div>

                  {derror?.err && (
                    <span role="alert" className="d-block error_text">
                      {derror.err}
                    </span>
                  )}
                  {picture !== "" ? (
                    <>
                      {picture === null ? (
                        ""
                      ) : (
                        <>
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              setPicture("");
                            }}
                            className="cancel_btn"
                          >
                            Retake
                          </Button>
                          <Button
                            type="button"
                            className="theme_btn ms-2"
                            onClick={handleProfileAdd}
                          >
                            Add
                          </Button>
                        </>
                      )}
                    </>
                  ) : (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        capture();
                      }}
                      className="theme_btn"
                    >
                      Capture
                    </Button>
                  )}

                  <Button
                    type="button"
                    className="cancel_btn ms-2"
                    onClick={handleCloseTake}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default CreateStudentForm;
