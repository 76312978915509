import React, { Suspense } from "react";
import { CircularProgress, Tab } from "@mui/material";
import "./index.scss";
import { Box, Modal } from "@mui/material";
import { Link } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CreateStudentTab from "./Create";
// import CreateStudentForm from "./CreateNew";
import AllergenTab from "./AllergenTab";
import { BsPlusSquareDotted } from "react-icons/bs";
import Button from "@mui/material/Button";
import closesquare from "../../../../../../Assets/PngImage/closesquare.png";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

function CreateStudent() {
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [values, setValues] = React.useState("1");

  // const handleChangeTab = (event, newValue) => {
  //   setValues(newValue);
  // };
  const handleChangeTab = (event, newValue) => {
    if (newValue === "2") {
      setOpen(true);
    } else {
      setOpen(false);
    }
    setValues(newValue);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let navigate = useNavigate();
  const onSubmit = () => {
    navigate("/prospects", { replace: true });
  };

  return (
    <Suspense fallback={renderLoader()}>
      <section className="create_product">
        <div className="main_wrapper">
          <TabContext value={values}>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Box className="tab_group">
                <TabList
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Student Information"
                    value="1"
                    className="tabs_link tab_1"
                  />
                  {/* <Tab
                    label="Allergen Information"
                    value="2"
                    className="tabs_link tab_2"
                  /> */}
                </TabList>
              </Box>
              {open ? (
                <div className="allergen-section text-end">
                  <Button className="theme_btn" onClick={handleOpen1}>
                    <BsPlusSquareDotted fontSize={24} className="me-3" />
                    Create
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
            <TabPanel value="1" className="tab_panel p-0">
              <CreateStudentTab />
              {/* <CreateStudentForm /> */}
            </TabPanel>
            <TabPanel value="2" className="tab_panel p-0">
              <AllergenTab />
            </TabPanel>
          </TabContext>
        </div>
      </section>

      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-pop"
      >
        <Box sx={style}>
          <div className="allergen-popup">
            <div className="add-text">
              {/* <h5></h5> */}
              <Link to="" onClick={handleClose1}>
                <img src={closesquare} alt="" />
              </Link>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Allergen Name <span className="red-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Allergen Name"
                      {...register("Allergen", {
                        required: "This field is required",
                        pattern: {
                          message: "Allergen Name",
                        },
                      })}
                    />
                    {errors.Allergen && (
                      <span role="alert" className="error_text">
                        {errors.Allergen.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Allergen Group <span className="red-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Allergen Group"
                      {...register("Allergengroup", {
                        required: "This field is required",
                        pattern: {
                          message: "Allergen Group",
                        },
                      })}
                    />
                    {errors.Allergengroup && (
                      <span role="alert" className="error_text">
                        {errors.Allergengroup.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 text-end">
                  <Button type="submit" className="theme_btn me-2">
                    Update
                  </Button>
                  <Button className="cancel_btn" onClick={handleClose1}>
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </Suspense>
  );
}

export default CreateStudent;
