import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import {
    Button,
    CircularProgress,
} from "@mui/material";
import "./index.scss";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { useDownloadExcel } from "react-export-table-to-excel";
// import { Config } from "../../../../../Utils/Config";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";

const ReportTable = lazy(() =>
    import("../../../../../Components/Reusable/ReportTable/index")
)

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

const osHead = [
    { id: "category", label: "Category" },
    { id: "currentMonth", label: "Current Month" },
    { id: "costMeal", label: "Cost/Meal" },
    { id: "yeartoDate", label: "Year To Date" },
    { id: "costMeal1", label: "Cost/Meal" },
]

const osData = [
    {
        category: "Meal Equivalents",
        currentMonth: "82,994",
        costMeal: "0.01",
        yeartoDate: "241,262",
        costMeal1: "0.016",
    },
    {
        category: "Meal Equivalents",
        currentMonth: "82,994",
        costMeal: "0.01",
        yeartoDate: "241,262",
        costMeal1: "0.016",
    },
    {
        category: "Meal Equivalents",
        currecostMeal1ntMonth: "82,994",
        costMeal: "0.01",
        yeartoDate: "241,262",
        costMeal1: "0.016",
    },
    {
        category: "Meal Equivalents",
        currentMonth: "82,994",
        costMeal: "0.01",
        yeartoDate: "241,262",
        costMeal1: "0.016",
    },
]

const inventoryHead = [
    { id: "type", label: "Type" },
    { id: "openingInventory", label: "Opening Inventory" },
    { id: "closingInventory", label: "Closing Inventory" },
    { id: "impact", label: "Impact" },
]

const inventoryData = [
    {
        type: "Food",
        openingInventory: "75,035.67",
        closingInventory: "60,990.06",
        impact: "14,045.61",
    },
    {
        type: "Food",
        openingInventory: "75,035.67",
        closingInventory: "60,990.06",
        impact: "14,045.61",
    },
    {
        type: "Food",
        openingInventory: "75,035.67",
        closingInventory: "60,990.06",
        impact: "14,045.61",
    },
]

const commodityHead = [
    { id: "currentMonth", label: "Current Month" },
    { id: "yeartoDate", label: "Year To Date" },
]

const commodityData = [
    {
        currentMonth: "22,425.28",
        yeartoDate: "53,371.27",
    },
]

const profitHead = [
    { id: "operatingProfit", label: "Operating Profit/(Loss)" },
    { id: "afterinventoryImpact", label: "After Inventory Impact" },
]

const profitData = [
    {
        operatingProfit: "47,632.42",
        afterinventoryImpact: "31,677.11",
    },
]


function FinancialDashboardReport() {
    const [parentList, setParentList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    // const [financialDash, setFinancialDash] = useState([]);
    const [openCircle, setOpenCircle] = useState(false);
    const [schoolSelect, setSchoolSelect] = useState([]);
    const [derror, setDerror] = useState();
    const [downloadExcel, setDownloadExcel] = useState(false);
    const tableRef = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        getDistrictList();
        // eslint-disable-next-line
    }, []);

    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    const getDistrictList = async () => {
        let seData = {
            url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
            headers: headers,
        };
        let res = await GetCallApi(seData);
        if (res?.status === 200) {
            if (res.data.success) {
                const dlist = res.data.payload?.map((sd) => {
                    return {
                        value: sd?.schoolDistrictId,
                        label: sd?.districtName,
                    };
                });
                setParentList(dlist?.length > 0 ? dlist : []);
            }
        }
    };

    const getSchoolList = async (id) => {
        setSchoolList([]);
        setSchoolSelect([]);
        if (id) {
            let seData = {
                url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
                headers: headers,
            };
            let res = await GetCallApi(seData);
            if (res?.status === 200) {
                if (res.data.success) {
                    const dlist = res.data.payload?.map((sd) => {
                        return {
                            value: sd?.schoolId,
                            label: sd?.schoolName,
                        };
                    });
                    let uslist = [{ value: "all", label: "Select All" }, ...dlist];

                    setSchoolList(uslist?.length > 1 ? uslist : []);
                }
            }
        } else {
            setSchoolList([]);
        }
    };
    // function mathRound(val) {
    //     return (Math.round(val * 100) / 100).toFixed(2);
    // }

    const getDashData = async (data) => {
        // setFinancialDash([]);
        let is_success = true;

        if (schoolSelect?.length === 0) {
            is_success = false;
            setDerror({ err: "Select minimum one school" });
        }
        if (is_success) {
            setDerror({ err: "" });
            setOpenCircle(true);
            let sendData = {
                ...data,
                userId: LogDetail?.userId,
                schoolId: schoolSelect?.map((x) => x.value),
            };
            // let distName = parentList?.filter(
            //     (x) => x.value === sendData?.schoolDistrictId
            // )[0]?.label;
            // let schName =
            //     schoolSelect?.length === schoolList?.length - 1
            //         ? "All"
            //         : schoolSelect?.map((x) => x.label).toString();

            // let dropData = {
            //     distName: distName,
            //     schName: schName,
            //     schoolDistrictId: data?.schoolDistrictId
            //         ? data?.schoolDistrictId
            //         : null,
            //     schoolId: data?.schoolId ? data?.schoolId : null,
            // };
            // setDropData(dropData);
            let seData = {
                url: `/api/Report/GetDCRTReport`,
                body: sendData,
                headers: headers,
            };
            let res = await PostCallApi(seData);
            setOpenCircle(false);

            if (res?.status === 200) {
                if (res?.data.success) {
                    // let resVal = res?.data.payload;

                    // const dList = res?.data.payload.dcrtReportSchoolList?.map((x) => {
                    //     return {
                    //         ...x,
                    //         dcrtCount: x?.dcrtCount?.toLocaleString(undefined, undefined),
                    //         dcrtPercentage: mathRound(x?.dcrtPercentage) + "%",
                    //         freeClaim: mathRound(x?.freeClaim) + "%",
                    //         paidClaim: mathRound(x?.paidClaim) + "%",
                    //     };
                    // });
                    // let newAr = [
                    //     ...dList,
                    //     {
                    //         schoolCode: "Summary: ",
                    //         schoolName: "",
                    //         enrollment: resVal?.totalEnrollment,
                    //         dcrtCount: resVal?.totalDCRTCount?.toLocaleString(
                    //             undefined,
                    //             undefined
                    //         ),
                    //         dcrtPercentage: mathRound(resVal?.totalDCRTPercentage) + "%",
                    //         freeClaim: mathRound(resVal?.totalFreeClaim) + "%",
                    //         paidClaim: mathRound(resVal?.totalPaidClaim) + "%",
                    //         addbr: true,
                    //     },
                    // ];
                    // setFinancialDash(newAr);
                } else {
                    // setFinancialDash([]);
                }
            }
        }
    };

    const handleIOCMulti = (e) => {
        setSchoolSelect(e);
    };

    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => currentYear - index);
    const months = [
        { name: "January", value: 1 },
        { name: "February", value: 2 },
        { name: "March", value: 3 },
        { name: "April", value: 4 },
        { name: "May", value: 5 },
        { name: "June", value: 6 },
        { name: "July", value: 7 },
        { name: "August", value: 8 },
        { name: "September", value: 9 },
        { name: "October", value: 10 },
        { name: "November", value: 11 },
        { name: "December", value: 12 },
    ];

    const reactToPrintContent = React.useCallback(() => {
        return tableRef.current;
    }, []);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "Invoice.pdf",
        removeAfterPrint: true,
    });

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Operating Report',
        sheet: "Report",
    });
    useEffect(() => {
        if (downloadExcel) {
            handleDownload();
        }
        // eslint-disable-next-line
    }, [downloadExcel]);

    const handleDownload = () => {
        onDownload();
        setDownloadExcel(false);
    };

    return (
        <>
            {/* <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
            <section className="financial_dashboard">
                <div className="main_wrapper">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="all_title mb-4">Financial Dashboard Report</h4>
                            <form onSubmit={handleSubmit(getDashData)}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                {" "}
                                                District
                                                <span className="red-color"> *</span>
                                            </label>
                                            <select
                                                name=""
                                                className="form-select"
                                                {...register("schoolDistrictId", {
                                                    required: "This field is required",
                                                    onChange: (e) => getSchoolList(e.target.value),
                                                })}
                                            >
                                                <option value="">Select School District</option>
                                                {parentList &&
                                                    parentList?.map((data, i) => (
                                                        <option key={i} value={data?.value}>
                                                            {data?.label}
                                                        </option>
                                                    ))}
                                            </select>
                                            {errors.schoolDistrictId && (
                                                <span role="alert" className="error_text">
                                                    {errors.schoolDistrictId.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                {" "}
                                                School
                                                <span className="red-color"> *</span>
                                            </label>
                                            <Select
                                                isMulti
                                                name="type"
                                                options={schoolList}
                                                // closeMenuOnSelect={false}
                                                // hideSelectedOptions={false}
                                                className="form-neselect"
                                                classNamePrefix="select"
                                                placeholder="Select School"
                                                value={schoolSelect ? schoolSelect : null}
                                                onChange={(selected) => {
                                                    true &&
                                                        selected.length &&
                                                        selected.find((option) => option.value === "all")
                                                        ? handleIOCMulti(schoolList.slice(1))
                                                        : !true
                                                            ? handleIOCMulti(
                                                                (selected && selected.value) || null
                                                            )
                                                            : handleIOCMulti(selected);
                                                }}
                                                style={{ width: "100%" }}
                                            />
                                            {derror?.err && (
                                                <span role="alert" className="d-block error_text">
                                                    {derror.err}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                Month
                                                <span className="red-color"> *</span>
                                            </label>
                                            <select
                                                className="form-select"
                                                {...register("month", {
                                                    required: "This field is required",
                                                })}
                                            >
                                                <option value="">Select Month</option>
                                                {months.map((month) => (
                                                    <option key={month.value} value={month.value}>
                                                        {month.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.month && (
                                                <span role="alert" className="error_text">
                                                    {errors.month.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                Year
                                                <span className="red-color"> *</span>
                                            </label>
                                            <select
                                                className="form-select"
                                                {...register("year", {
                                                    required: "This field is required",
                                                })}
                                            >
                                                <option value="">Select Year</option>
                                                {years.map((year) => (
                                                    <option key={year} value={year}>
                                                        {year}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.year && (
                                                <span role="alert" className="error_text">
                                                    {errors.year.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="ms-auto mt-auto col-md-3">
                                        <div className="form-group text-end filter-img">
                                            {!openCircle ? (
                                                <>
                                                    <Button
                                                        className="cancel_btn me-3 text-end"
                                                        type="button"
                                                        onClick={() => handlePrint()}
                                                    >
                                                        Pdf
                                                    </Button>
                                                    <Button
                                                        className="cancel_btn me-3 text-end"
                                                        type="button"
                                                        onClick={() => setDownloadExcel(true)}
                                                    >
                                                        Download
                                                    </Button>
                                                </>
                                            ) : (
                                                ""
                                            )}

                                            {openCircle ? (
                                                <Button className="theme_btn text-end">
                                                    Applying Filter{" "}
                                                    <CircularProgress
                                                        sx={{
                                                            color: "#fff",
                                                            marginLeft: "0.5rem",
                                                            height: "23px !important",
                                                            width: "23px !important",
                                                        }}
                                                    />
                                                </Button>
                                            ) : (
                                                <Button className="theme_btn text-end" type="submit">
                                                    <img
                                                        src={filter}
                                                        className="me-1"
                                                        alt=""
                                                        height={23}
                                                    />
                                                    Apply
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <Suspense fallback={renderLoader()}>
                                <div ref={tableRef}>
                                    <style type="text/css" media="print">
                                        {` 
                                        @page{
                                            size : A4
                                        }
                                            
                                        @media print {
                                            .download_div {
                                                break-inside: avoid;
                                                page-break-inside: avoid;
                                            }                     
                                        }
                                        `}
                                    </style>
                                    <div ref={tableRef}>
                                        <div className="col-md-12 download_div">
                                            <>
                                                <ReportTable
                                                    mainTitleStyle={{ background: 'linear-gradient(86.33deg,#18bad4 1.51%,#31b680 52.02%,#80c242 99.48%)', color: downloadExcel ? "black" : "white" }}
                                                    TableHeader={osHead}
                                                    title={"Operating Statement"}
                                                    isImage={false}
                                                    TableData={osData}
                                                />
                                                <ReportTable
                                                    mainTitleStyle={{ background: 'linear-gradient(86.33deg,#18bad4 1.51%,#31b680 52.02%,#80c242 99.48%)', color: downloadExcel ? "black" : "white" }}
                                                    TableHeader={inventoryHead}
                                                    title={"Inventory"}
                                                    isImage={false}
                                                    TableData={inventoryData}
                                                />
                                                <ReportTable
                                                    mainTitleStyle={{ background: 'linear-gradient(86.33deg,#18bad4 1.51%,#31b680 52.02%,#80c242 99.48%)', color: downloadExcel ? "black" : "white" }}
                                                    TableHeader={commodityHead}
                                                    title={"Commodity Value"}
                                                    isImage={false}
                                                    TableData={commodityData}
                                                />
                                                <ReportTable
                                                    mainTitleStyle={{ background: 'linear-gradient(86.33deg,#18bad4 1.51%,#31b680 52.02%,#80c242 99.48%)', color: downloadExcel ? "black" : "white" }}
                                                    TableHeader={profitHead}
                                                    title={"Profit/Loss"}
                                                    isImage={false}
                                                    TableData={profitData}
                                                />
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </Suspense>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FinancialDashboardReport;
