import React, { Suspense, useEffect, useState } from "react";
import {
    Accordion,
    AccordionSummary,
    Backdrop,
    Button,
    CircularProgress,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import "./index.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { AccordionDetails } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import attach_img from "../../../../../../Assets/PngImage/attach.png";
import default_image from "../../../../../../Assets/default_profile.jpg";
import { v4 } from "uuid";
import { VscTrash } from "react-icons/vsc";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import { useSelector } from "react-redux";
import { Config } from "../../../../../../Utils/Config";
import Swal from "sweetalert2";

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

const InvoiceCreate = () => {
    const { id } = useParams();

    let defaultVal = 0?.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
    });
    let curDate = new Date();
    const [open, setOpen] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showReference, setShowReference] = useState(false);
    const [derrorFile, setDerrorFile] = useState("");
    const [fileSize, setFileSize] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imageError, setImageError] = useState("");
    const [template, setTemplate] = useState("Amount");
    const [financeVendorList, setFinanceVendorList] = useState([]);
    const [totalItemAmount, setTotalItemAmount] = useState(defaultVal);
    const [userAddress, setUserAddress] = useState([]);
    const [address, setAddress] = useState();
    const [schoolList, setSchoolList] = useState([]);
    const [distList, setDistList] = useState([]);
    const [cmpData, setCmpData] = useState([]);
    const [sendTotal, setSendTotal] = useState();

    let nav = useNavigate();

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        clearErrors,
        control,
        formState: { errors },
    } = useForm();

    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);

    const {
        fields: itemList,
        append: appendItems,
        remove: removeItems,
        replace: replaceItems,
    } = useFieldArray({
        control,
        name: "subItems",
    });

    useEffect(() => {
        if (!id) {
            appendItems({
                itemName: "",
                amount: "",
                tax: 0,
                quantity: "",
                description: "",
            });
            getInvoiceNumber();
            getVenderList();
            getDistrictList();
        }
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     if (userAddress?.length === 0) {
    //         setValue("vendorId", "");
    //     }
    //     // eslint-disable-next-line
    // }, [userAddress]);

    useEffect(() => {
        if (id) {
            getEditIvoice(id);
        }
        // eslint-disable-next-line
    }, [id]);

    const getEditIvoice = async (invoiceId) => {
        setOpen(true);
        if (invoiceId) {
            let apiUrl = {
                url: `/api/Invoices/GetInvoiceDataByID?invoiceId=${invoiceId}`,
            };
            const res = await GetCallApi(apiUrl);
            setOpen(false);
            if (res?.status === 200 && res?.data?.success) {
                let allData = res?.data?.payload;
                getDistrictList(allData?.schoolDistrictId);
                getSchoolList(allData?.schoolDistrictId, allData?.schoolId);
                getVenderList(allData?.vendorId);
                setTemplate(allData?.templateName);
                setSelectedFiles(
                    allData?.invoiceAttachments?.map((c) => {
                        return {
                            ...c,
                            id: c?.invoiceAttachmentId,
                            name: c?.attachmentName,
                            isActive: true,
                        };
                    })
                );
                setValue("memo", allData?.memo);
                setValue("vendorId", allData?.vendorId);
                setValue("invoiceId", invoiceId);
                setValue("templateName", allData?.templateName);
                setValue("vendorAddressId", allData?.vendorAddressId);
                setValue("dueDate", allData?.dueDate?.split("T")[0]);
                setValue("invoiceDate", allData?.invoiceDate?.split("T")[0]);
                setValue("invoiceNumber", allData?.invoiceNumber);
                setValue("sellerNoteToCustomer", allData?.sellerNoteToCustomer);
                if (allData?.invoiceItems?.length > 0) {
                    setValue(
                        "subItems",
                        allData?.invoiceItems?.map((x) => {
                            return { ...x };
                        })
                    );
                } else {
                    appendItems({
                        itemName: "",
                        amount: "",
                        tax: 0,
                        quantity: "",
                        description: "",
                    });
                }
                if (allData?.sellerTermsCondition) {
                    setShowTerms(true);
                    setValue("sellerTermsCondition", allData?.sellerTermsCondition);
                }
                if (allData?.referenceNumber) {
                    setShowReference(true);
                    setValue("referenceNumber", allData?.referenceNumber);
                }
                setTimeout(() => {
                    setAddress(allData?.vendorAddressId);
                }, 500);

                setTimeout(() => {
                    handleAmount();
                }, 800);
            }
        }
    };

    const getDistrictList = async (sdId) => {
        let seData = {
            url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
        };
        let res = await GetCallApi(seData);
        if (res?.status === 200 && res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
                return {
                    value: sd?.schoolDistrictId,
                    label: sd?.districtName,
                };
            });
            setDistList(dlist?.length > 0 ? dlist : []);
            if (sdId && dlist?.length > 0) {
                setTimeout(() => {
                    setValue("schoolDistrictId", sdId);
                }, 500);
            }
        }
    };

    const getInvoiceNumber = async () => {
        let seData = {
            url: `/api/Invoices/GetInvoiceNumber`,
        };
        let res = await GetCallApi(seData);
        if (res?.status === 200 && res?.data.success) {
            setTimeout(() => {
                setValue("invoiceNumber", res?.data?.payload);
            }, 500);
        }
    };

    const getSchoolList = async (id, schoolId) => {
        setSchoolList([]);
        setValue("schoolId", "");
        if (id) {
            let seData = {
                url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
            };
            let res = await GetCallApi(seData);
            if (res?.status === 200 && res.data.success) {
                const dlist = res.data.payload?.map((sd) => {
                    return {
                        ...sd,
                        value: sd?.schoolId,
                        label: sd?.schoolName,
                    };
                });
                setSchoolList(dlist?.length > 0 ? dlist : []);
                if (schoolId && dlist?.length > 0) {
                    setTimeout(() => {
                        setValue("schoolId", schoolId);
                        setTimeout(() => {
                            setCmpData(dlist?.find((x) => x?.value === schoolId));
                        }, 500);
                    }, 1000);
                }
            }
        } else {
            setSchoolList([]);
        }
    };

    const getVenderList = async (vId) => {
        setFinanceVendorList([]);

        let sendBody = {
            schoolDistrictId: "00000000-0000-0000-0000-000000000000",
            schoolId: [],
        };

        let seData = {
            url: `/api/FinanceVendor/GetFinanceVendorsData`,
            body: sendBody,
        };

        let resp = await PostCallApi(seData);
        if (resp?.status === 200) {
            if (resp?.data?.success) {
                const sdData = resp.data.payload?.map((c) => {
                    return {
                        ...c,
                    };
                });
                setFinanceVendorList(sdData ?? []);
                if (vId && sdData?.length > 0) {
                    setTimeout(() => {
                        setValue("vendorId", vId);
                        let ad = sdData?.find((x) => x?.vendorId === vId);
                        setUserAddress(
                            ad?.vendorAddress?.map((x) => {
                                return { ...x, name: ad?.vendorName };
                            })
                        );
                    }, 500);
                }
            }
        }
    };

    const handleAddAddress = (val) => {
        clearErrors("vendorAddressId");
        let ad = financeVendorList?.find((x) => x?.vendorId === val);
        setAddress();
        setUserAddress(
            ad ? ad?.vendorAddress?.map((x) => {
                return { ...x, name: ad?.vendorName };
            }) : []
        );

    };

    const handleFile = (e) => {
        const oldFileCount =
            selectedFiles?.filter((c) => c?.isActive === true)?.length || 0;
        const newFiles = Array.from(e.target?.files || []);
        const newFileCount = newFiles.length;

        if (oldFileCount + newFileCount > 5) {
            setDerrorFile(`You have already selected 5 files`);
            setImageError("");
            e.target.value = "";
            return;
        }

        let isSuccess = true;
        let totalSize = fileSize || 0;
        const validFiles = [];
        const newImageUrls = [];

        const MAX_FILE_SIZE = 4194304; // 4MB

        newFiles.forEach((file) => {
            totalSize += file.size;

            if (file.size > MAX_FILE_SIZE) {
                setDerrorFile(`File size exceeds 4MB. Please select smaller files.`);
                isSuccess = false;
            } else if (isSuccess) {
                validFiles.push(file);
                newImageUrls.push(file);
            }
        });

        if (isSuccess && validFiles.length > 0) {
            setImageError("");
            setDerrorFile("");

            const processedFiles = newImageUrls.map((file) => ({
                id: v4(),
                isActive: true,
                file,
                imagePath: file,
                isNew: true,
            }));

            setFileSize(totalSize);
            setSelectedFiles((old) => [...old, ...processedFiles]);
        }

        // Reset file input to allow re-upload of the same file
        e.target.value = "";
    };

    const handleDelete = (imgId) => {
        setDerrorFile("");
        let minus = imgId?.file?.size;
        let nad = imgId?.isNew;
        let arSave = nad
            ? selectedFiles?.filter((d) => d?.id !== imgId?.id)
            : selectedFiles?.map((d) => {
                if (d?.id === imgId?.id)
                    return {
                        ...d,
                        isActive: false,
                    };
                return d;
            });
        setSelectedFiles(arSave);
        setFileSize(fileSize - minus);
    };

    const handleAppend = (add) => {
        if (add) {
            appendItems({
                itemName: "",
                amount: "",
                tax: 0,
                quantity: "",
                description: "",
            });
        } else {
            replaceItems([
                {
                    itemName: "",
                    amount: "",
                    tax: 0,
                    quantity: "",
                    description: "",
                },
            ]);
        }
    };

    const handleTemplateChange = (val) => {
        setTemplate(val);
        handleAppend("");
        const subItems = getValues("subItems") || [];

        subItems?.forEach((_, index) => {
            clearErrors([
                `subItems[${index}].quantity`,
                `subItems[${index}].price`,
                `subItems[${index}].hours`,
                `subItems[${index}].rate`,
                `subItems[${index}].amount`,
                `subItems[${index}].itemName`,
            ]);
        });
    };

    const handleAmount = () => {
        let List = getValues("subItems");

        let total = List?.map((x) => parseFloat(x?.amount) || 0).reduce(
            (acc, current) => acc + current,
            0
        );

        let formattedTotal = total?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
        setSendTotal(total?.toFixed(2));
        setTotalItemAmount(formattedTotal);
    };

    const onSubmit = async (data) => {
        setSaveLoader(true);
        let invoiceData = {
            ...data,
            subTotal: sendTotal ? parseInt(sendTotal) : 0,
            totalAmount: sendTotal ? parseInt(sendTotal) : 0,
            createdBy: LogDetail?.userId,
        };
        let sendInvoice = {
            url: `/api/Invoices/CreateIvoices`,
            body: invoiceData,
        };

        const resp = await PostCallApi(sendInvoice);
        if (resp?.status === 200 && resp?.data?.success) {
            let invoiceID = resp?.data?.payload?.invoiceId;

            if (data?.subItems?.length > 0) {
                let seItems = {
                    invoiceId: invoiceID,
                    createdBy: LogDetail?.userId,
                    invoiceItemSubs: data?.subItems?.map((x) => {
                        return {
                            ...x,
                            quantity: parseInt(x?.quantity) ?? 0,
                            price: parseFloat(x?.price) ?? 0,
                            hours: parseFloat(x?.hours) ?? 0,
                            rate: parseFloat(x?.rate) ?? 0,
                            amount: parseFloat(x?.amount) ?? 0,
                            description: x?.description ?? "",
                            itemName: x?.itemName ?? "",
                        };
                    }),
                };

                let sendSubItems = {
                    url: `/api/Invoices/SaveMultipleInvoiceItem`,
                    body: seItems,
                };

                await PostCallApi(sendSubItems);
                setSaveLoader(false);
            }

            if (selectedFiles?.length > 0) {
                selectedFiles &&
                    selectedFiles?.map(async (x, i) => {
                        let seData = {
                            InvoiceAttachmentURL: x?.file ? x?.file : x?.attachmentUrl,
                            InvoiceAttachmentId: x?.invoiceAttachmentId,
                            InvoiceId: invoiceID,
                            CreatedBy: LogDetail?.userId,
                            IsActive: x?.isActive,
                        };

                        let apiUrl = {
                            url: `/api/Invoices/SaveInvoiceAttachment`,
                            body: seData,
                            headers: {
                                Authorization: `Bearer ${token}`,
                                "Content-Type": "multipart/form-data",
                                Accept: "*/*",
                            },
                        };

                        await PostCallApi(apiUrl);
                        if (selectedFiles?.length === i + 1 && data?.sendMail) {
                            MailSend(invoiceID)
                        }
                    });
            }

            nav(-1);
            Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: resp?.data.message,
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "#48953e",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        } else {
            setSaveLoader(false);
            Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: resp?.data?.message
                    ? resp?.data?.message
                    : "Something went wrong please try again.",
                showConfirmButton: false,
                timer: 2000,
                color: "#ffffff",
                background: "red",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        }
    };
    const MailSend = async (invoiceID) => {
        let sendMailData = {
            url: `/api/Invoices/sendInvoiceMailForVendor?invoiceId=${invoiceID}&createdBy=${LogDetail?.userId}`,
        };

        await PostCallApi(sendMailData);
    }
    const handleSchool = (sch) => {
        let sendInfo = schoolList?.find((x) => x?.value === sch);
        setCmpData(sendInfo);
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="create_invoice">
                    <div className="main_wrapper">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="ProductCategories_hed">
                                <div className="all_title ProductTitle">
                                    {id ? "Edit" : "Create"} Invoice
                                </div>
                                <div className="d-flex">
                                    <Button className="cancel_btn me-3" onClick={() => nav(-1)}>
                                        Cancel
                                    </Button>
                                    {saveLoader ? (
                                        <Button className="theme_btn text-end">
                                            {/* Sending Mail{" "} */}
                                            <CircularProgress
                                                sx={{
                                                    color: "#fff",
                                                    marginLeft: "0.5rem",
                                                    height: "23px !important",
                                                    width: "23px !important",
                                                }}
                                            />
                                        </Button>
                                    ) : (
                                        <>
                                            <Button className="theme_btn me-3" type="submit">
                                                Save
                                            </Button>
                                            <Button
                                                style={{ textTransform: "capitalize" }}
                                                className="theme_btn"
                                                onClick={handleSubmit(async (data) =>
                                                    onSubmit({ ...data, sendMail: true })
                                                )}
                                            >
                                                Save and send
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-8">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <div className="row border-bottom mb-3">
                                                <div className="ms-auto col-md-4">
                                                    <div className="form-group">
                                                        <label>Template</label>
                                                        <select
                                                            name=""
                                                            className="form-select"
                                                            {...register("templateName", {
                                                                onChange: (e) => {
                                                                    handleTemplateChange(e.target.value);
                                                                    handleAmount();
                                                                },
                                                            })}
                                                        >
                                                            <option value="Amount">Amount</option>
                                                            <option value="Quantity">Quantity</option>
                                                            <option value="Hours">Hours</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>Currency</label>
                                                        <select
                                                            name=""
                                                            className="form-select"
                                                            {...register("currency", {})}
                                                        >
                                                            <option value="USD">USD</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="main_div">
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-md-10 col-12">
                                                        <h5>
                                                            <b>Bill to</b>
                                                        </h5>
                                                        <div className="form-group">
                                                            <select
                                                                name=""
                                                                className="form-select"
                                                                {...register("vendorId", {
                                                                    required: "This field is required",
                                                                    onChange: (e) =>
                                                                        handleAddAddress(e.target.value),
                                                                })}
                                                            >
                                                                <option value="">Email address Or name</option>
                                                                {financeVendorList?.map((c, ind) => (
                                                                    <option key={ind} value={c?.vendorId}>
                                                                        {c?.vendorName}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {errors?.vendorId && (
                                                                <span role="alert" className="error_text">
                                                                    {errors?.vendorId?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 col-12 d-flex justify-content-md-end justify-content-start mt-md-4 mb-3">
                                                        <Link
                                                            to={`/fig-finance/finance-vendor/create`}
                                                            className="theme_btn"
                                                        >
                                                            Add Vendor
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        {userAddress &&
                                                            userAddress.map((x, index) => (
                                                                <div className="col-md-4" key={index}>
                                                                    <RadioGroup
                                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                                        name="radio-buttons-group"
                                                                        className=""
                                                                        onChange={(e) => setAddress(e.target.value)}
                                                                        value={address ? address : ""}
                                                                    >
                                                                        <React.Fragment key={index}>
                                                                            <label
                                                                                id="radio-buttons-group"
                                                                                className="customer_info mb-3"
                                                                            >
                                                                                <div className="d-flex justify-content-between align-items-center">
                                                                                    <h5>{x?.name}</h5>
                                                                                    <span className="close_btn">
                                                                                        <FormControlLabel
                                                                                            value={x?.vendorAddressId}
                                                                                            {...register(`vendorAddressId`, {
                                                                                                required: "Select address",
                                                                                            })}
                                                                                            control={<Radio />}
                                                                                        />
                                                                                    </span>
                                                                                </div>
                                                                                <p className="address">{x?.address}</p>
                                                                            </label>
                                                                        </React.Fragment>
                                                                    </RadioGroup>
                                                                </div>
                                                            ))}
                                                        {errors?.vendorAddressId && (
                                                            <span role="alert" className="error_text mb-3">
                                                                {errors?.vendorAddressId.message}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <h5>
                                                    <b>Items</b>
                                                </h5>
                                                <div className="col-md-12">
                                                    <div className="items_div">
                                                        {/* {template === "Amount" ? */}
                                                        <>
                                                            {itemList &&
                                                                itemList?.map((_, ind) => {
                                                                    if (template === "Amount") {
                                                                        return (
                                                                            <div
                                                                                className={`d-flex align-items-center ${itemList?.length !== ind + 1
                                                                                    ? "border-bottom my-3"
                                                                                    : ""
                                                                                    } gap-3`}
                                                                                key={ind}
                                                                            >
                                                                                <div className="w-60">
                                                                                    <div className="form-group">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Item Name"
                                                                                            {...register(
                                                                                                `subItems.${ind}.itemName`,
                                                                                                {
                                                                                                    required:
                                                                                                        "This field is required",
                                                                                                    pattern: {
                                                                                                        value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                                                        message: "Starting and Ending Space not allowed",
                                                                                                    },
                                                                                                }
                                                                                            )}
                                                                                        />
                                                                                        {errors?.subItems?.[ind]
                                                                                            ?.itemName && (
                                                                                                <span
                                                                                                    role="alert"
                                                                                                    className="error_text"
                                                                                                >
                                                                                                    {
                                                                                                        errors?.subItems?.[ind]
                                                                                                            ?.itemName.message
                                                                                                    }
                                                                                                </span>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-40 d-flex gap-3 align-items-center">
                                                                                    <div className="form-group w-100">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Amount"
                                                                                            {...register(
                                                                                                `subItems.${ind}.amount`,
                                                                                                {
                                                                                                    onChange: (e) => {
                                                                                                        handleAmount();
                                                                                                    },
                                                                                                    pattern: {
                                                                                                        value: /^\d+(\.\d{1,2})?$/,
                                                                                                        message:
                                                                                                            "Please enter correct format  ex. 0.75 or 12",
                                                                                                    },
                                                                                                    required:
                                                                                                        "This field is required",
                                                                                                }
                                                                                            )}
                                                                                        />
                                                                                        {errors?.subItems?.[ind]
                                                                                            ?.amount && (
                                                                                                <span
                                                                                                    role="alert"
                                                                                                    className="error_text"
                                                                                                >
                                                                                                    {
                                                                                                        errors?.subItems?.[ind]
                                                                                                            ?.amount.message
                                                                                                    }
                                                                                                </span>
                                                                                            )}
                                                                                    </div>
                                                                                    {ind === 0 ? (
                                                                                        ""
                                                                                    ) : (
                                                                                        <>
                                                                                            <div
                                                                                                className="remove_btn"
                                                                                                onClick={() => {
                                                                                                    removeItems(ind);
                                                                                                    handleAmount();
                                                                                                }}
                                                                                            >
                                                                                                <RxCross1 />
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    if (template === "Hours") {
                                                                        return (
                                                                            <div
                                                                                className={`main_hours ${itemList?.length !== ind + 1
                                                                                    ? "border-bottom my-3"
                                                                                    : ""
                                                                                    } gap-3`}
                                                                                key={ind}
                                                                            >
                                                                                <div className="d-flex align-items-center gap-3">
                                                                                    <div className="hours_item">
                                                                                        <div className="form-group">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="form-control"
                                                                                                placeholder="Item Name"
                                                                                                {...register(
                                                                                                    `subItems.${ind}.itemName`,
                                                                                                    {
                                                                                                        required:
                                                                                                            "This field is required",
                                                                                                        pattern: {
                                                                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                                                            message: "Starting and Ending Space not allowed",
                                                                                                        },
                                                                                                    }
                                                                                                )}
                                                                                            />
                                                                                            {errors?.subItems?.[ind]
                                                                                                ?.itemName && (
                                                                                                    <span
                                                                                                        role="alert"
                                                                                                        className="error_text"
                                                                                                    >
                                                                                                        {
                                                                                                            errors?.subItems?.[ind]
                                                                                                                ?.itemName.message
                                                                                                        }
                                                                                                    </span>
                                                                                                )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="sub_hours">
                                                                                        <div className="form-group">
                                                                                            <input
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                placeholder="Hours"
                                                                                                {...register(
                                                                                                    `subItems.${ind}.hours`,
                                                                                                    {
                                                                                                        required:
                                                                                                            "This field is required",
                                                                                                    }
                                                                                                )}
                                                                                            />
                                                                                            {errors?.subItems?.[ind]
                                                                                                ?.hours && (
                                                                                                    <span
                                                                                                        role="alert"
                                                                                                        className="error_text"
                                                                                                    >
                                                                                                        {
                                                                                                            errors?.subItems?.[ind]
                                                                                                                ?.hours.message
                                                                                                        }
                                                                                                    </span>
                                                                                                )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="sub_rate">
                                                                                        <div className="form-group">
                                                                                            <input
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                placeholder="Rate"
                                                                                                {...register(
                                                                                                    `subItems.${ind}.amount`,
                                                                                                    {
                                                                                                        onChange: (e) =>
                                                                                                            handleAmount(),
                                                                                                        required:
                                                                                                            "This field is required",
                                                                                                        pattern: {
                                                                                                            value:
                                                                                                                /^\d+(\.\d{1,2})?$/,
                                                                                                            message:
                                                                                                                "Please enter correct format  ex. 0.75 or 12",
                                                                                                        },
                                                                                                    }
                                                                                                )}
                                                                                            />
                                                                                            {errors?.subItems?.[ind]
                                                                                                ?.amount && (
                                                                                                    <span
                                                                                                        role="alert"
                                                                                                        className="error_text"
                                                                                                    >
                                                                                                        {
                                                                                                            errors?.subItems?.[ind]
                                                                                                                ?.amount.message
                                                                                                        }
                                                                                                    </span>
                                                                                                )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="sub_desc d-flex gap-3 align-items-center my-3">
                                                                                    <div className="form-group w-100">
                                                                                        <textarea
                                                                                            rows={5}
                                                                                            className="form-control"
                                                                                            placeholder="Description (optional)"
                                                                                            {...register(
                                                                                                `subItems.${ind}.description`,
                                                                                                {
                                                                                                    pattern: {
                                                                                                        value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                                                        message: "Starting and Ending Space not allowed",
                                                                                                    },
                                                                                                }
                                                                                            )}
                                                                                        />
                                                                                        {errors?.subItems?.[ind]
                                                                                            ?.description && (
                                                                                                <span
                                                                                                    role="alert"
                                                                                                    className="error_text"
                                                                                                >
                                                                                                    {
                                                                                                        errors?.subItems?.[ind]
                                                                                                            ?.description.message
                                                                                                    }
                                                                                                </span>
                                                                                            )}
                                                                                    </div>
                                                                                    {ind === 0 ? (
                                                                                        ""
                                                                                    ) : (
                                                                                        <>
                                                                                            <div
                                                                                                className="remove_btn"
                                                                                                onClick={() => {
                                                                                                    removeItems(ind);
                                                                                                    handleAmount();
                                                                                                }}
                                                                                            >
                                                                                                <RxCross1 />
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    return (
                                                                        <div
                                                                            className={`main_quantity ${itemList?.length !== ind + 1
                                                                                ? "border-bottom my-3"
                                                                                : ""
                                                                                } gap-3`}
                                                                            key={ind}
                                                                        >
                                                                            <div className="d-flex align-items-center gap-3">
                                                                                <div className="quantity_item">
                                                                                    <div className="form-group">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Item Name"
                                                                                            {...register(
                                                                                                `subItems.${ind}.itemName`,
                                                                                                {
                                                                                                    required:
                                                                                                        "This field is required",
                                                                                                    pattern: {
                                                                                                        value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                                                        message: "Starting and Ending Space not allowed",
                                                                                                    },
                                                                                                }
                                                                                            )}
                                                                                        />
                                                                                        {errors?.subItems?.[ind]
                                                                                            ?.itemName && (
                                                                                                <span
                                                                                                    role="alert"
                                                                                                    className="error_text"
                                                                                                >
                                                                                                    {
                                                                                                        errors?.subItems?.[ind]
                                                                                                            ?.itemName.message
                                                                                                    }
                                                                                                </span>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="sub_quantity_name">
                                                                                    <div className="form-group">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            placeholder="Quantity"
                                                                                            {...register(
                                                                                                `subItems.${ind}.quantity`,
                                                                                                {
                                                                                                    required:
                                                                                                        "This field is required",
                                                                                                }
                                                                                            )}
                                                                                        />
                                                                                        {errors?.subItems?.[ind]
                                                                                            ?.quantity && (
                                                                                                <span
                                                                                                    role="alert"
                                                                                                    className="error_text"
                                                                                                >
                                                                                                    {
                                                                                                        errors?.subItems?.[ind]
                                                                                                            ?.quantity.message
                                                                                                    }
                                                                                                </span>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="sub_quantity_price">
                                                                                    <div className="form-group">
                                                                                        <input
                                                                                            type="text"
                                                                                            className="form-control"
                                                                                            placeholder="Price"
                                                                                            {...register(
                                                                                                `subItems.${ind}.amount`,
                                                                                                {
                                                                                                    onChange: (e) =>
                                                                                                        handleAmount(),
                                                                                                    required:
                                                                                                        "This field is required",
                                                                                                    pattern: {
                                                                                                        value: /^\d+(\.\d{1,2})?$/,
                                                                                                        message:
                                                                                                            "Please enter correct format  ex. 0.75 or 12",
                                                                                                    },
                                                                                                }
                                                                                            )}
                                                                                        />
                                                                                        {errors?.subItems?.[ind]
                                                                                            ?.amount && (
                                                                                                <span
                                                                                                    role="alert"
                                                                                                    className="error_text"
                                                                                                >
                                                                                                    {
                                                                                                        errors?.subItems?.[ind]
                                                                                                            ?.amount.message
                                                                                                    }
                                                                                                </span>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="quantity_tax">
                                                                                    <div className="form-group">
                                                                                        <select
                                                                                            name=""
                                                                                            className="form-select"
                                                                                            {...register(
                                                                                                `subItems.${ind}.tax`
                                                                                            )}
                                                                                        >
                                                                                            <option value={0}>No tax</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="sub_quantity_desc d-flex gap-3 align-items-center my-3">
                                                                                <div className="form-group w-100">
                                                                                    <textarea
                                                                                        rows={5}
                                                                                        className="form-control"
                                                                                        placeholder="Description (optional)"
                                                                                        {...register(
                                                                                            `subItems.${ind}.description`,
                                                                                            {
                                                                                                pattern: {
                                                                                                    value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                                                    message: "Starting and Ending Space not allowed",
                                                                                                },
                                                                                            }
                                                                                        )}
                                                                                    />
                                                                                    {errors?.subItems?.[ind]
                                                                                        ?.description && (
                                                                                            <span
                                                                                                role="alert"
                                                                                                className="error_text"
                                                                                            >
                                                                                                {
                                                                                                    errors?.subItems?.[ind]
                                                                                                        ?.description.message
                                                                                                }
                                                                                            </span>
                                                                                        )}
                                                                                </div>
                                                                                {ind === 0 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <>
                                                                                        <div
                                                                                            className="remove_btn"
                                                                                            onClick={() => {
                                                                                                removeItems(ind);
                                                                                                handleAmount();
                                                                                            }}
                                                                                        >
                                                                                            <RxCross1 />
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </>
                                                        {/* template === "Hours" ?
                                                            <>
                                                                {hoursList && hoursList?.map((_, id) => {
                                                                    return (
                                                                        <div className={`main_hours ${hoursList?.length !== id + 1 ? 'border-bottom my-3' : ""} gap-3`} key={id}>
                                                                            <div className='d-flex align-items-center gap-3'>
                                                                                <div className='hours_item'>
                                                                                    <div className="form-group">
                                                                                        <select
                                                                                            name=""
                                                                                            className="form-select"
                                                                                            {...register(`subHours.${id}.Houritem`, {
                                                                                            })}
                                                                                        >
                                                                                            <option value="">Item name</option>
                                                                                            {items?.map((x, ind) => (
                                                                                                <option key={ind} value={x?.id}>
                                                                                                    {x?.name}
                                                                                                </option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='sub_hours'>
                                                                                    <div className="form-group">
                                                                                        <input
                                                                                            type="number"
                                                                                            min={1}
                                                                                            className="form-control"
                                                                                            placeholder="Hours"
                                                                                            {...register(`subHours.${id}.hours`, {
                                                                                            })}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='sub_rate'>
                                                                                    <div className="form-group">
                                                                                        <input
                                                                                            type="number"
                                                                                            min={1}
                                                                                            className="form-control"
                                                                                            placeholder="Rate"
                                                                                            {...register(`subHours.${id}.rate`, {
                                                                                            })}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='sub_desc d-flex gap-3 align-items-center my-3'>
                                                                                <div className="form-group w-100">
                                                                                    <textarea
                                                                                        rows={5}
                                                                                        className="form-control"
                                                                                        placeholder="Description (optional)"
                                                                                        {...register(`subHours.${id}.Hourdescription`, {
                                                                                        })}
                                                                                    />
                                                                                </div>
                                                                                {id === 0 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <>
                                                                                        <div className='remove_btn' onClick={() => removeHours(id)}>
                                                                                            <RxCross1 />
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                            :
                                                            template === "Quantity" ?
                                                                <>
                                                                    {quantityList && quantityList?.map((_, index) => {
                                                                        return (
                                                                            <div className={`main_quantity ${quantityList?.length !== index + 1 ? 'border-bottom my-3' : ""} gap-3`} key={index}>
                                                                                <div className='d-flex align-items-center gap-3'>
                                                                                    <div className='quantity_item'>
                                                                                        <div className="form-group">
                                                                                            <select
                                                                                                name=""
                                                                                                className="form-select"
                                                                                                {...register(`subHours.${index}.Quantityitem`, {
                                                                                                })}
                                                                                            >
                                                                                                <option value="">Item name</option>
                                                                                                {items?.map((x, ind) => (
                                                                                                    <option key={ind} value={x?.id}>
                                                                                                        {x?.name}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='sub_quantity_name'>
                                                                                        <div className="form-group">
                                                                                            <input
                                                                                                type="number"
                                                                                                min={1}
                                                                                                className="form-control"
                                                                                                placeholder="Quantity"
                                                                                                {...register(`subHours.${index}.quantity`, {
                                                                                                })}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='sub_quantity_price'>
                                                                                        <div className="form-group">
                                                                                            <input
                                                                                                type="number"
                                                                                                min={1}
                                                                                                className="form-control"
                                                                                                placeholder="Price"
                                                                                                {...register(`subHours.${index}.price`, {
                                                                                                })}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='quantity_tax'>
                                                                                        <div className="form-group">
                                                                                            <select
                                                                                                name=""
                                                                                                className="form-select"
                                                                                                {...register(`subHours.${index}.tax`, {
                                                                                                })}
                                                                                            >
                                                                                                <option value="">Tax</option>
                                                                                                <option value="no Tax">
                                                                                                    No tax
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='sub_quantity_desc d-flex gap-3 align-items-center my-3'>
                                                                                    <div className="form-group w-100">
                                                                                        <textarea
                                                                                            rows={5}
                                                                                            className="form-control"
                                                                                            placeholder="Description (optional)"
                                                                                            {...register(`subHours.${index}.Quantitydescription`, {
                                                                                            })}
                                                                                        />
                                                                                    </div>
                                                                                    {index === 0 ? (
                                                                                        ""
                                                                                    ) : (
                                                                                        <>
                                                                                            <div className='remove_btn' onClick={() => removeQuantity(index)}>
                                                                                                <RxCross1 />
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </>
                                                                : ""
                                                    } */}
                                                        <div className="d-flex justify-content-end">
                                                            <h5>
                                                                <b>Amount: {totalItemAmount}</b>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <Button
                                                        className="add_item"
                                                        onClick={() => handleAppend(true)}
                                                    >
                                                        + Add item or service
                                                    </Button>
                                                </div>
                                                <div className="col-md-12 mt-2">
                                                    <h5 className="mb-3">
                                                        <b>Message to customer</b>
                                                    </h5>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <textarea
                                                                rows={5}
                                                                className="form-control"
                                                                placeholder="Seller note to customer"
                                                                {...register(`sellerNoteToCustomer`, {
                                                                    pattern: {
                                                                        value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                        message: "Starting and Ending Space not allowed",
                                                                    },
                                                                })}
                                                            />
                                                            {errors?.sellerNoteToCustomer && (
                                                                <span role="alert" className="error_text">
                                                                    {errors?.sellerNoteToCustomer?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="terms_ref my-3 gap-3">
                                                        <Button
                                                            className="add_terms_condition_number"
                                                            onClick={() => setShowTerms(!showTerms)}
                                                        >
                                                            {showTerms ? "-" : "+"} Add terms and conditions
                                                        </Button>{" "}
                                                        |{" "}
                                                        <Button
                                                            className="add_terms_condition_number"
                                                            onClick={() => setShowReference(!showReference)}
                                                        >
                                                            {showReference ? "-" : "+"} Add reference number
                                                        </Button>
                                                    </div>
                                                    {showTerms && (
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <textarea
                                                                    rows={5}
                                                                    className="form-control"
                                                                    placeholder="Seller's terms and conditions"
                                                                    {...register(`sellerTermsCondition`, {
                                                                        pattern: {
                                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                            message: "Starting and Ending Space not allowed",
                                                                        },
                                                                    })}
                                                                />
                                                                {errors?.sellerTermsCondition && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors?.sellerTermsCondition?.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {showReference && (
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Reference"
                                                                    {...register(`referenceNumber`, {
                                                                        pattern: {
                                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                            message: "Starting and Ending Space not allowed",
                                                                        },
                                                                    })}
                                                                />
                                                                {errors?.referenceNumber && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors?.referenceNumber?.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="col-md-12">
                                                    <Accordion defaultExpanded={true}>
                                                        <AccordionSummary
                                                            expandIcon={<GridExpandMoreIcon />}
                                                            aria-controls="panel1-content"
                                                            id="panel1-header"
                                                        >
                                                            {/* <Typography> */}
                                                            <h5>
                                                                <b>More Options</b>
                                                            </h5>
                                                            {/* </Typography> */}
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div className="row">
                                                                <div className="col-md-12 mt-2">
                                                                    <h6 className="mb-3">
                                                                        <b>Attachments</b>
                                                                    </h6>
                                                                    <div className="col-md-12">
                                                                        <label
                                                                            htmlFor="cmp_docs"
                                                                            className="attach_img_file"
                                                                        >
                                                                            <div className="upload-container">
                                                                                <img
                                                                                    src={attach_img}
                                                                                    className="icon"
                                                                                    alt="attachment"
                                                                                />
                                                                                <span className="upload-text">
                                                                                    Upload File
                                                                                </span>
                                                                            </div>
                                                                            <input
                                                                                type="file"
                                                                                id="cmp_docs"
                                                                                multiple
                                                                                accept=".jpg, .jpeg, .png, .pdf, .gif"
                                                                                className="attach_files"
                                                                                style={{ display: "none" }}
                                                                                onChange={handleFile}
                                                                            />
                                                                        </label>
                                                                        {imageError && (
                                                                            <span role="alert" className="error_text">
                                                                                {imageError}
                                                                            </span>
                                                                        )}
                                                                        {derrorFile && (
                                                                            <span role="alert" className="error_text">
                                                                                {derrorFile}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-md-12 my-3">
                                                                        JPG GIF PNG PDF &nbsp; | &nbsp; Up to 5
                                                                        files, 4MB per file
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                            {selectedFiles &&
                                                                                selectedFiles
                                                                                    ?.filter((x) => x?.isActive === true)
                                                                                    ?.map((x, i) => {
                                                                                        return (
                                                                                            <div className="col-md-3" key={i}>
                                                                                                <div className="file_box_main mb-3">
                                                                                                    <div className="num">
                                                                                                        {x?.attachmentUrl ? (
                                                                                                            <Link
                                                                                                                to={`${Config?.API_HOST_URL_live}${x?.attachmentUrl}`}
                                                                                                                target="_blank"
                                                                                                            >
                                                                                                                <img
                                                                                                                    src={attach_img}
                                                                                                                    alt="attachment"
                                                                                                                />
                                                                                                            </Link>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="text_data">
                                                                                                        {x?.file?.name
                                                                                                            ? x?.file?.name
                                                                                                            : x?.name}
                                                                                                    </div>
                                                                                                    <button
                                                                                                        className="btn_dlt"
                                                                                                        type="button"
                                                                                                        onClick={() =>
                                                                                                            handleDelete(x)
                                                                                                        }
                                                                                                    >
                                                                                                        <VscTrash className="icon" />
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 mt-2">
                                                                    <h6 className="mb-3">
                                                                        <b>Memo to self</b>
                                                                    </h6>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <textarea
                                                                                rows={5}
                                                                                className="form-control"
                                                                                placeholder="Memo"
                                                                                {...register(`memo`, {
                                                                                    pattern: {
                                                                                        value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                                        message: "Starting and Ending Space not allowed",
                                                                                    },
                                                                                })}
                                                                            />
                                                                            {errors?.memo && (
                                                                                <span role="alert" className="error_text">
                                                                                    {errors?.memo?.message}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <div className="col-md-12">
                                                <div className="row gap-xl-0 gap-3">
                                                    {cmpData?.schoolName ? (
                                                        ""
                                                    ) : (
                                                        <>
                                                            <div className="col-xl-6 col-12">
                                                                <select
                                                                    name=""
                                                                    className="form-select"
                                                                    {...register("schoolDistrictId", {
                                                                        onChange: (e) =>
                                                                            getSchoolList(e.target.value),
                                                                        required: "This field is required",
                                                                    })}
                                                                >
                                                                    <option value="">Select District</option>
                                                                    {distList &&
                                                                        distList?.map((data, i) => (
                                                                            <option key={i} value={data?.value}>
                                                                                {data?.label}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                {errors.schoolDistrictId && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors.schoolDistrictId.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div className="col-xl-6 col-12">
                                                                <select
                                                                    className="form-select"
                                                                    {...register("schoolId", {
                                                                        onChange: (e) =>
                                                                            handleSchool(e.target.value),
                                                                        required: "This field is required",
                                                                    })}
                                                                >
                                                                    <option value="">Select School </option>
                                                                    {schoolList &&
                                                                        schoolList?.map((item, i) => (
                                                                            <option key={i} value={item?.value}>
                                                                                {item?.label}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                {errors.schoolId && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors.schoolId.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}
                                                    {cmpData?.schoolName ? (
                                                        <>
                                                            <div className="col-xl-4 col-12">
                                                                <span className="cmp_image">
                                                                    <img
                                                                        src={
                                                                            cmpData?.districtLogo
                                                                                ? `${Config?.API_HOST_URL_live}${cmpData?.districtLogo}`
                                                                                : ""
                                                                        }
                                                                        className="img-fluid w-100"
                                                                        alt="attachment"
                                                                        onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null;
                                                                            currentTarget.src = default_image;
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                            <div className="col-xl-8 col-12">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="district_address">
                                                                        <h6>{cmpData?.schoolDistrictName}</h6>
                                                                        <h6>{cmpData?.schoolName}</h6>
                                                                        <p className="m-0">{cmpData?.address}</p>
                                                                    </div>
                                                                    <span
                                                                        className="cross_icon"
                                                                        onClick={() => setCmpData([])}
                                                                    >
                                                                        &times;
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Invoice Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Invoice Number"
                                                        disabled={true}
                                                        {...register("invoiceNumber", {
                                                            // required: "This field is required",
                                                            maxLength: {
                                                                value: 250,
                                                                message: "Max 250 characters",
                                                            },
                                                            pattern: {
                                                                value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                message:
                                                                    "Starting and Ending Space not allowed",
                                                            },
                                                        })}
                                                    />
                                                    {/* {errors?.invoiceNumber && (
                                                    <span role="alert" className="error_text">
                                                        {errors?.invoiceNumber.message}
                                                    </span>
                                                )} */}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Invoice Date</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        defaultValue={
                                                            !id && curDate?.toISOString()?.split("T")[0]
                                                        }
                                                        {...register("invoiceDate", {
                                                            required: "This field is required",
                                                            valueAsDate: true,
                                                        })}
                                                    />
                                                    {errors?.invoiceDate && (
                                                        <span role="alert" className="error_text">
                                                            {errors?.invoiceDate.message}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="border-top border-bottom card-container my-1">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>Due Date</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        defaultValue={
                                                            !id && curDate?.toISOString()?.split("T")[0]
                                                        }
                                                        {...register("dueDate", {
                                                            required: "This field is required",
                                                            valueAsDate: true,
                                                        })}
                                                    />
                                                    {errors?.dueDate && (
                                                        <span role="alert" className="error_text">
                                                            {errors?.dueDate.message}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 my-2">
                                            <div className="total_discount">
                                                <div className="sub_total">
                                                    <p>Subtotal</p>
                                                    <span>{totalItemAmount}</span>
                                                </div>
                                                {/* <div className='sub_total'>
                                                <p>Other discount</p>
                                                <button>Add</button>
                                            </div>
                                            <div className='sub_total'>
                                                <p>Shipping</p>
                                                <button>Add</button>
                                            </div> */}
                                            </div>
                                            <hr />
                                            <div className="total_discount mb-2">
                                                {/* <div className='sub_total'>
                                                <p>Other Amount</p>
                                                <button>Add</button>
                                            </div> */}
                                                <div className="sub_total">
                                                    <p>
                                                        Total
                                                        <span style={{ color: "#2CC56F" }}>
                                                            {" "}
                                                            (Tax excl.){" "}
                                                        </span>
                                                    </p>
                                                    <span style={{ color: "#2CC56F" }}>
                                                        {totalItemAmount}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </Suspense>
        </>
    );
};

export default InvoiceCreate;
