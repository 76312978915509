import React, { useEffect, useState, Suspense, useRef } from "react";
import "./invoiceview.scss";
import { useParams } from "react-router-dom";
import { GetCallApi } from "../../../../../../Action/Action";
import attach_img from "../../../../../../Assets/PngImage/attach.png";
import { MdRemoveRedEye } from "react-icons/md";
import { Config } from "../../../../../../Utils/Config";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useReactToPrint } from "react-to-print";

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

function Ivoiceview() {
    const { id } = useParams();
    const tableRef = useRef();

    const [invoice, setInvoice] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (id) {
            getInvoiceData(id);
        }
        // eslint-disable-next-line
    }, [id]);

    const getInvoiceData = async (invoiceId) => {
        setOpen(true);
        if (invoiceId) {
            let apiUrl = {
                url: `/api/Invoices/GetInvoiceDataByID?invoiceId=${invoiceId}`,
            };
            const res = await GetCallApi(apiUrl);
            setOpen(false);
            if (res?.status === 200 && res?.data?.success) {
                let allData = res?.data?.payload;
                setInvoice(allData ?? {});
            }
        }
    };

    function convert(str) {
        var date = new Date(str?.replace(/-/g, "/")),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }

    const reactToPrintContent = React.useCallback(() => {
        return tableRef.current;
    }, []);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "Invoice.pdf",
        removeAfterPrint: true,
    });

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <style type="text/css" media="print">
                    {`
                  @page {
                     size: A4
                        }
                `}
                </style>
                <div className="btn_control pt-3 pb-3">
                    <Button className="theme_btn" onClick={handlePrint}>
                        Download
                    </Button>
                    <Button className="cancel_btn" onClick={() => window.close()}>
                        Cancel
                    </Button>
                </div>
                <section className="invoice_pdf" ref={tableRef}>
                    <div className="card">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="col-md-12">
                                    <div className="cmp_detail mb-3">
                                        <div className="cmp_img">
                                            <img
                                                src={`${Config?.API_HOST_URL_live}${invoice?.districtLogo}`}
                                                alt=""
                                                height={"90px"}
                                            />
                                        </div>
                                        <div className="cmp_address">
                                            <h5>{invoice?.districtName}</h5>
                                            <h5>{invoice?.schoolName}</h5>
                                            <p style={{ color: "#787878" }}>
                                                {invoice?.schoolAddress}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="vendor_details">
                                        <div className="name_address_amount">
                                            <span style={{ color: "#787878" }}>Invoice to:</span>
                                            <h4>{invoice?.vendorName}</h4>
                                            <p>{invoice?.vendorAddress}</p>
                                        </div>
                                        <div className="name_address_amount">
                                            <span style={{ color: "#787878" }}>Amount due</span>
                                            <h4>
                                                {invoice?.subTotal?.toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency: "USD",
                                                })}
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="invoice_meta">
                                    <div className="meta_item">
                                        <span style={{ color: "#787878" }}>Invoice number:</span>
                                        <strong>{invoice?.invoiceNumber}</strong>
                                    </div>
                                    <div className="meta_item">
                                        <span style={{ color: "#787878" }}>Issued:</span>
                                        <strong>
                                            {convert(invoice?.invoiceDate?.split("T")[0])}
                                        </strong>
                                    </div>
                                    <div className="meta_item">
                                        <span style={{ color: "#787878" }}>Due Date:</span>
                                        <strong>{convert(invoice?.dueDate?.split("T")[0])}</strong>
                                    </div>
                                </div>
                            </div>
                            {invoice?.invoiceItems?.length > 0 ?
                                <>
                                    <h5 className="item_title">Items</h5>
                                    <div className="item_table table-responsive mb-3">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Item</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoice?.invoiceItems?.map((c) => {
                                                    return (
                                                        <tr>
                                                            <td>{c?.itemName}</td>
                                                            <td>
                                                                {c?.amount?.toLocaleString("en-US", {
                                                                    style: "currency",
                                                                    currency: "USD",
                                                                })}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                : ""}

                            <section className="summary col-md-12">
                                <div className="notes">
                                    <strong>Seller Note</strong>
                                    <p>{invoice?.sellerNoteToCustomer}</p>
                                    <strong>Reference</strong>
                                    <p>{invoice?.referenceNumber}</p>
                                </div>
                                <div className="totals">
                                    <div>
                                        <span>Subtotal</span>
                                        <h5>
                                            {invoice?.subTotal?.toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                            })}
                                        </h5>
                                    </div>
                                    <div className="invoice-total">
                                        <span>Invoice total</span>
                                        <span>
                                            {invoice?.subTotal?.toLocaleString("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                            })}
                                        </span>
                                    </div>
                                </div>
                            </section>

                            <section className="col-md-12 terms">
                                <strong>Seller's terms and conditions</strong>
                                <p>{invoice?.sellerTermsCondition}</p>
                            </section>

                            <h5 className="attach_title">Attachments</h5>
                            {invoice?.invoiceAttachments?.map((x, i) => {
                                return (
                                    <section className="attachments py-1 px-2">
                                        <div style={{ cursor: "pointer" }}>
                                            <img src={attach_img} alt="attachment" />
                                            <span>{x?.attachmentName}</span>
                                        </div>
                                        <p className="align-items-center mb-0">
                                            <a
                                                href={`${Config?.API_HOST_URL_live}${x?.attachmentUrl}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <span>
                                                    <MdRemoveRedEye fontSize={24} color="#2cc56f" />
                                                </span>
                                            </a>
                                        </p>
                                    </section>
                                );
                            })}
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default Ivoiceview;
