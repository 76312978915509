import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import { useSelector } from "react-redux";
import { PostCallApi } from "../../../../../Action/Action";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";

const ReportTable = lazy(() =>
    import("../../../../../Components/Reusable/ReportTable/index")
);

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

const inventoryData = [
    {
        category: "meat cheese",
        beginningInventory: "$0.00",
        purchasesperAP: "$0.00",
        tjvs: "$0.00",
        subTotal: "$0.00",
        endingInventory: "$0.00",
        cost: "$0.00",
    },
    {
        category: "fresh frozen produce",
        beginningInventory: "$0.00",
        purchasesperAP: "$31,034.55",
        tjvs: "$0.00",
        subTotal: "$31,034.55",
        endingInventory: "$0.00",
        cost: "$31,034.55",
    },
    {
        category: "groceries",
        beginningInventory: "$0.00",
        purchasesperAP: "$31,034.55",
        tjvs: "$0.00",
        subTotal: "$31,034.55",
        endingInventory: "$0.00",
        cost: "$31,034.55",
    },
]

const statisticsData = [

    {
        category: "breakfast",
        totalMeals: "21846",
        marketValue: "$67,258.00",
        totalHours: "-",
    },
    {
        category: "snack",
        totalMeals: "-",
        marketValue: "$0.00",
        totalHours: "666",
    },
    {
        category: "lunch",
        totalMeals: "38202",
        marketValue: "$0.00",
        totalHours: "-",
    },
    {
        category: "milk",
        totalMeals: "1319",
        marketValue: "$0.00",
        totalHours: "-",
    },
    {
        category: "equivalent meals",
        totalMeals: "5225",
        marketValue: "$0.00",
        totalHours: "-",
    },
]

const summaryData = [
    {
        category: "student breakfast sales",
        value: "$12.00",
        percentage: "0.01%",
    },
    {
        category: "student breakfast sales",
        value: "$12.00",
        percentage: "0.01%",
    },
    {
        category: "student breakfast sales",
        value: "$12.00",
        percentage: "0.01%",
    },
]

const LaborCotsData = [
    {
        category: "meat cheese",
        value: "$0.00",
        percentage: "0%",
    },
    {
        category: "meat cheese",
        value: "$0.00",
        percentage: "0%",
    },
    {
        category: "meat cheese",
        value: "$0.00",
        percentage: "0%",
    },
]

const administrativeData = [
    {
        category: "cafeteria",
        value: "$8,678.78",
        percentage: "3.7%",
    },
    {
        category: "cafeteria",
        value: "$8,678.78",
        percentage: "3.7%",
    },
    {
        category: "cafeteria",
        value: "$8,678.78",
        percentage: "3.7%",
    }
]

const inventoryHead = [
    { id: "category", label: "Category" },
    { id: "beginningInventory", label: "Beginning Inventory" },
    { id: "purchasesperAP", label: "Purchases Per A/P" },
    { id: "tjvs", label: "TJVS" },
    { id: "subTotal", label: "Sub Total" },
    { id: "endingInventory", label: "Ending Inventory" },
    { id: "cost", label: "Cost" },
];

const statisticsHead = [
    { id: "category", label: "Category" },
    { id: "totalMeals", label: "Total Meals" },
    { id: "marketValue", label: "Market Value" },
    { id: "totalHours", label: "Total Hours" },
];

const summaryHead = [
    { id: "category", label: "Category" },
    { id: "value", label: "Value" },
    { id: "percentage", label: "Percentage" },
];

const LaborCotsHead = [
    { id: "category", label: "Category" },
    { id: "value", label: "Value" },
    { id: "percentage", label: "Percentage" },
]

const administrativeHead = [
    { id: "category", label: "Category" },
    { id: "value", label: "Value" },
    { id: "percentage", label: "Percentage" },
]

function OperatingReport() {
    const [openCircle, setOpenCircle] = useState(false);
    const [downloadExcel, setDownloadExcel] = useState(false);
    const tableRef = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    const getDeposit = async (data) => {
        // setDeposit([]);
        let is_success = true;

        // if (schoolSelect?.length === 0) {
        //     is_success = false;
        //     setDerror({ err: "Select minimum one school" });
        // }
        if (is_success) {
            // setDerror({ err: "" });
            setOpenCircle(true);
            let sendData = {
                ...data,
                userId: LogDetail?.userId,
                // schoolId: schoolSelect?.map((x) => x.value),
            };
            // let distName = parentList?.filter(
            //     (x) => x.value === sendData?.schoolDistrictId
            // )[0]?.label;
            // let schName =
            //     schoolSelect?.length === schoolList?.length - 1
            //         ? "All"
            //         : schoolSelect?.map((x) => x.label).toString();

            // let dropData = {
            //     distName: distName,
            //     schName: schName,
            //     schoolDistrictId: data?.schoolDistrictId
            //         ? data?.schoolDistrictId
            //         : null,
            //     schoolId: data?.schoolId ? data?.schoolId : null,
            // };
            // setDropData(dropData);
            let seData = {
                url: `/api/Report/GetDCRTReport`,
                body: sendData,
                headers: headers,
            };
            await PostCallApi(seData);
            setOpenCircle(false);

            // if (res?.status === 200) {
            //     if (res?.data.success) {
            // const dList = statisticsHead?.map((x) => {
            //     return {
            //         ...x,
            //         accountName: x?.name,
            //         currentMonth: x?.Current,
            //         yearToDate: x?.Previous,
            //         costMealMonth: x?.Rate,
            //         costMealYear: x?.PreviousRate
            //     };
            // });
            // setDeposit(dList);
            //     } else {
            //         setDeposit([]);
            //     }
            // }
        }
    };
    // const handleIOCMulti = (e) => {
    //     setSchoolSelect(e);
    // };

    // const currentYear = new Date().getFullYear();
    // const years = Array.from(new Array(10), (val, index) => currentYear - index);
    const months = [
        { name: "January", value: 1 },
        { name: "February", value: 2 },
        { name: "March", value: 3 },
        { name: "April", value: 4 },
        { name: "May", value: 5 },
        { name: "June", value: 6 },
        { name: "July", value: 7 },
        { name: "August", value: 8 },
        { name: "September", value: 9 },
        { name: "October", value: 10 },
        { name: "November", value: 11 },
        { name: "December", value: 12 },
    ];

    const reactToPrintContent = React.useCallback(() => {
        return tableRef.current;
    }, []);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "Invoice.pdf",
        removeAfterPrint: true,
    });

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'Operating Report',
        sheet: "Report",
    });
    useEffect(() => {
        if (downloadExcel) {
            handleDownload();
        }
        // eslint-disable-next-line
    }, [downloadExcel]);

    const handleDownload = () => {
        onDownload();
        setDownloadExcel(false);
    };

    return (
        <>
            {/* <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
            <section className="Operating_statement_report">
                <div className="main_wrapper">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="all_title mb-4">Operating Report</h4>
                            <form onSubmit={handleSubmit(getDeposit)}>
                                <div className="row">
                                    {/* <div className="col-md-3">
                                        <div className="form-group">
                                            <label> District
                                                <span className="red-color"> *</span>
                                            </label>
                                            <select
                                                name=""
                                                className="form-select"
                                                {...register("schoolDistrictId", {
                                                    required: "This field is required",
                                                    onChange: (e) => getSchoolList(e.target.value),
                                                })}
                                            >
                                                <option value="">Select School District</option>
                                                {parentList &&
                                                    parentList?.map((data, i) => (
                                                        <option key={i} value={data?.value}>
                                                            {data?.label}
                                                        </option>
                                                    ))}
                                            </select>
                                            {errors.schoolDistrictId && (
                                                <span role="alert" className="error_text">
                                                    {errors.schoolDistrictId.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label> School
                                                <span className="red-color"> *</span>
                                            </label>
                                            <Select
                                                isMulti
                                                name="type"
                                                options={schoolList}
                                                // closeMenuOnSelect={false}
                                                // hideSelectedOptions={false}
                                                className="form-neselect"
                                                classNamePrefix="select"
                                                placeholder="Select School"
                                                value={schoolSelect ? schoolSelect : null}
                                                onChange={(selected) => {
                                                    true &&
                                                        selected.length &&
                                                        selected.find((option) => option.value === "all")
                                                        ? handleIOCMulti(schoolList.slice(1))
                                                        : !true
                                                            ? handleIOCMulti(
                                                                (selected && selected.value) || null
                                                            )
                                                            : handleIOCMulti(selected);
                                                }}
                                                style={{ width: "100%" }}
                                            />
                                            {derror?.err && (
                                                <span role="alert" className="d-block error_text">
                                                    {derror.err}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                Year
                                                <span className="red-color"> *</span>
                                            </label>
                                            <select
                                                className="form-select"
                                                {...register("year", {
                                                    required: "This field is required",
                                                })}
                                            >
                                                <option value="">Select Year</option>
                                                {years.map((year) => (
                                                    <option key={year} value={year}>
                                                        {year}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.year && (
                                                <span role="alert" className="error_text">
                                                    {errors.year.message}
                                                </span>
                                            )}
                                        </div>
                                    </div> */}
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                Month
                                            </label>
                                            <select
                                                className="form-select"
                                                {...register("month", {
                                                    // required: "This field is required",
                                                })}
                                            >
                                                <option value="">Select Month</option>
                                                {months.map((month) => (
                                                    <option key={month.value} value={month.value}>
                                                        {month.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.month && (
                                                <span role="alert" className="error_text">
                                                    {errors.month.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className=" ms-auto mt-auto col-md-4">
                                        <div className="form-group text-end filter-img">
                                            {/* {dropdata?.scName || dropdata?.distName ? ( */}
                                            <>
                                                <Button
                                                    className="cancel_btn me-3 text-end"
                                                    type="button"
                                                    onClick={() => handlePrint()}
                                                >
                                                    Pdf
                                                </Button>
                                                <Button
                                                    className="cancel_btn me-3 text-end"
                                                    type="button"
                                                onClick={() => onDownload()}
                                                >
                                                    Download
                                                </Button>
                                            </>
                                            {/* ) : (
                                                ""
                                            )} */}

                                            {openCircle ? (
                                                <Button className="theme_btn text-end">
                                                    Applying Filter{" "}
                                                    <CircularProgress
                                                        sx={{
                                                            color: "#fff",
                                                            marginLeft: "0.5rem",
                                                            height: "23px !important",
                                                            width: "23px !important",
                                                        }}
                                                    />
                                                </Button>
                                            ) : (
                                                <Button className="theme_btn text-end" type="submit">
                                                    <img
                                                        src={filter}
                                                        className="me-1"
                                                        alt=""
                                                        height={23}
                                                    />
                                                    Apply
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <Suspense fallback={renderLoader()}>
                                    <style type="text/css" media="print">
                                        {` 
                                        @page{
                                            size : A4
                                        }
                                            
                                        @media print {
                                            .download_div {
                                                break-inside: avoid;
                                                page-break-inside: avoid;
                                            }                     
                                        }
                                        `}
                                    </style>
                                <div ref={tableRef}>
                                    <div className="col-md-12 download_div">
                                        <>
                                            <ReportTable
                                                TableHeader={inventoryHead}
                                                title={"Inventory Report"}
                                                isImage={true}
                                                TableData={inventoryData}
                                            />

                                            <ReportTable
                                                TableHeader={statisticsHead}
                                                title={"Operating Statistics"}
                                                isImage={true}
                                                TableData={statisticsData}
                                            />
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <ReportTable
                                                        TableHeader={summaryHead}
                                                        title={"Operating Summary"}
                                                        isImage={true}
                                                        TableData={summaryData}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <ReportTable
                                                        TableHeader={LaborCotsHead}
                                                        title={"Food and Labor Costs"}
                                                        isImage={true}
                                                        TableData={LaborCotsData}
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <ReportTable
                                                        TableHeader={administrativeHead}
                                                        title={"Direct Administrative Costs"}
                                                        isImage={true}
                                                        TableData={administrativeData}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </Suspense>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OperatingReport;
